export class PotreeRenderer {
  constructor(viewer) {
    this.viewer = viewer;
  }

  clearTargets() {}

  clear() {
    const viewer = this.viewer;
    const { renderer, skybox, background, scene } = viewer;

    // render skybox
    if (viewer.background === "skybox") {
      renderer.setClearColor(0x000000, 0);
      renderer.clear(true, true, false);
    } else if (viewer.background === "gradient") {
      renderer.setClearColor(0x000000, 0);
      renderer.clear(true, true, false);
    } else if (viewer.background === "black") {
      renderer.setClearColor(0x000000, 1);
      renderer.clear(true, true, false);
    } else if (viewer.background === "white") {
      renderer.setClearColor(0xffffff, 1);
      renderer.clear(true, true, false);
    } else {
      renderer.setClearColor(0x000000, 0);
      renderer.clear(true, true, false);
    }
  }

  render(params) {
    const viewer = this.viewer;
    const camera = params.camera
      ? params.camera
      : viewer.scene.getActiveCamera();
    const { renderer } = viewer;

    viewer.dispatchEvent({ type: "render.pass.begin", viewer: viewer });

    const renderAreaSize = renderer.getSize(new THREE.Vector2());
    const width = params.viewport ? params.viewport[2] : renderAreaSize.x;
    const height = params.viewport ? params.viewport[3] : renderAreaSize.y;

    // render skybox
    if (viewer.background === "skybox") {
      viewer.skybox.camera.rotation.copy(viewer.scene.cameraP.rotation);
      viewer.skybox.camera.fov = viewer.scene.cameraP.fov;
      viewer.skybox.camera.aspect = viewer.scene.cameraP.aspect;
      viewer.skybox.camera.updateProjectionMatrix();
      renderer.render(viewer.skybox.scene, viewer.skybox.camera);
    } else if (viewer.background === "gradient") {
      renderer.render(viewer.scene.sceneBG, viewer.scene.cameraBG);
    }

    for (let pointcloud of this.viewer.scene.pointclouds) {
      pointcloud.material.useEDL = false;
    }

    viewer.pRenderer.render(viewer.scene.scenePointCloud, camera, null, {
      clipSpheres: viewer.scene.volumes.filter(
        v => v instanceof Potree.SphereVolume
      ),
    });

    // render scene
    viewer.renderer.render(viewer.scene.scene, camera);

    viewer.dispatchEvent({ type: "render.pass.scene", viewer: viewer });

    viewer.clippingTool.update();
    viewer.renderer.render(
      viewer.clippingTool.sceneMarker,
      viewer.scene.cameraScreenSpace
    ); //viewer.scene.cameraScreenSpace);
    viewer.renderer.render(viewer.clippingTool.sceneVolume, camera);

    viewer.renderer.render(viewer.controls.sceneControls, camera);

    viewer.renderer.clearDepth();

    viewer.transformationTool.update();

    viewer.dispatchEvent({
      type: "render.pass.perspective_overlay",
      viewer: viewer,
    });

    viewer.renderer.render(viewer.transformationTool.scene, camera);

    viewer.renderer.setViewport(
      viewer.renderer.domElement.clientWidth -
        viewer.navigationCube.width +
        viewer.navigationCube.margin,
      viewer.renderer.domElement.clientHeight -
        viewer.navigationCube.width +
        viewer.navigationCube.margin,
      viewer.navigationCube.width,
      viewer.navigationCube.width
    );
    viewer.renderer.render(viewer.navigationCube, viewer.navigationCube.camera);
    viewer.renderer.setViewport(
      0,
      0,
      viewer.renderer.domElement.clientWidth,
      viewer.renderer.domElement.clientHeight
    );

    viewer.dispatchEvent({ type: "render.pass.end", viewer: viewer });
  }
}
